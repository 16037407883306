import {
  FeedbackStep,
  RatingStepType,
  FeatureCompleteStepType,
  UserInputStepType,
  ThankYouStepType,
  FeedbackSteps,
  FeedbackCacheKeys,
} from './types'
import { UserInputStep as UserInputStepComponent }
  from './components/UserInputStep'
import { RatingStep as RatingStepComponent }
  from './components/RatingStep'
import { ThankYouStep as ThankYouStepComponent }
  from './components/ThankYouStep'
import { FeatureCompleteStep as FeatureCompleteStepComponent }
  from './components/FeatureCompleteStep'

export const RatingStepData: RatingStepType = {
  description: [
    { content: 'How likely is it that you would ' },
    { content: 'recommend the JUUL App', tagName: 'strong' },
    { content: ' to an adult smoker friend?' },
  ],
  dial: {
    legend: ['not at all likely', 'extremely likely'],
    numberOfOptions: 10,
  },
}

export const FeatureCompleteStepData: FeatureCompleteStepType = {
  description: [
    { content: 'Do you feel the JUUL App has ' },
    { content: 'all', tagName: 'strong' },
    { content: ' the features you are looking for?' },
  ],
  options: ['yes', 'no'],
}

export const UserInputStepData: UserInputStepType = {
  forms: [
    {
      icon: { name: 'icon/24x24/face_neutral_outlined' },
      message: [
        { content: 'Is there anything we should ' },
        { content: 'change', tagName: 'strong' },
        { content: ' or ' },
        { content: 'add', tagName: 'strong' },
        { content: ' to the JUUL App?' },
      ],
      name: FeedbackCacheKeys.NEGATIVE_FEEDBACK,
    },
    {
      icon: { name: 'icon/24x24/face_happy_outlined' },
      message: [
        { content: 'What do you ' },
        { content: 'like', tagName: 'strong' },
        { content: ' about the JUUL App?' },
      ],
      name: FeedbackCacheKeys.POSITIVE_FEEDBACK,
    },
  ],
  maxLength: 1024,
}

export const ThankYouStepData: ThankYouStepType = {
  description: [
    { content: 'Your feedback truly counts – we review every comment!' },
  ],
  icon: { name: 'icon/24x24/take_away_message-outlined' },
  title: { content: 'Thank you.', tagName: 'strong' },
}

export const FeatureCompleteStep: FeedbackStep = {
  name: 'FeatureCompleteStep',
  primaryAction: { target: 'UserInputStep' },
  props: {
    component: FeatureCompleteStepComponent,
    primaryButton: null,
  },
}

export const UserInputStep: FeedbackStep = {
  name: 'UserInputStep',
  primaryAction: { target: 'ThankYouStep' },
  props: {
    component: UserInputStepComponent,
    primaryButton: { content: 'submit' },
  },
}

export const RatingStep: FeedbackStep = {
  name: 'RatingStep',
  primaryAction: { target: 'FeatureCompleteStep' },
  props: {
    component: RatingStepComponent,
    primaryButton: { content: 'continue' },
  },
}

export const ThankYouStep: FeedbackStep = {
  name: 'ThankYouStep',
  primaryAction: { target: null },
  props: {
    component: ThankYouStepComponent,
    primaryButton: { content: 'close' },
  },
}

export default {
  FeatureCompleteStep,
  RatingStep,
  ThankYouStep,
  UserInputStep,
} as FeedbackSteps
