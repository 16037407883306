import React, { FC, HTMLAttributes, PropsWithChildren } from 'react'
import styles from './Drawer.module.scss'
import { Box } from 'components/Box'
import { Button } from 'components/Button'

export interface DrawerProps extends HTMLAttributes<HTMLDivElement> {
  onClose?: () => void
}

export const CLOSE_BUTTON_LABEL = 'Close popup.'

export const Drawer: FC<PropsWithChildren<DrawerProps>> = ({
  children,
  onClose,
  ...rest
}) => (
  <Box className={styles.Drawer} {...rest}>
    <Box align='right' padding={{ left: 'base', right: 'base', top: 'base' }}>
      <Button
        accessibilityLabel={CLOSE_BUTTON_LABEL}
        icon='icon/16x16/close'
        onClick={onClose}
        size='small'
        type='button'
      />
    </Box>
    {children}
  </Box>
)
