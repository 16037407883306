import React, { FC } from 'react'

import { Icon } from '@juullabs/react-components'
import { Text } from 'components/Text'
import { Box } from 'components/Box'

import styles from './ThankYouStep.module.scss'

import { ThankYouStepData } from '../../data'
import { FeedbackStepComponentProps } from '../../types'

export const ThankYouStep: FC<FeedbackStepComponentProps> = () => {
  const { description, title, icon } = ThankYouStepData
  return (
    <Box
      className={styles.ThankYouStep}
      align='center'>
      <Box margin={{ bottom: 'extremeTight' }}>
        <Icon className={styles.icon} {...icon} />
      </Box>
      <Text
        margin={{ bottom: 'baseLoose' }}
        align='center'
        typography='bodyBase'
        message={title}
        tagName='p'
      />
      <Text
        className={styles.description}
        align='center'
        typography='bodyBase'
        message={description}
        tagName='p'
      />
    </Box>
  )
}
