import React, {
  FC,
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { FeedbackCard } from './components/FeedbackCard'
import { Drawer } from './components/Drawer'
import FeedbackSteps from './data'
import { useAuthentication } from 'modules/AuthenticationProvider'
import Chai from 'types/chai'
import {
  FeedbackContextType,
  FeedbackCardProps,
  FeedbackAction,
  RecordDataCallback,
  FeedbackCacheKeys,
  FeedbackEntryCache,
  FeedbackStep,
  PageChecklistType,
} from './types'
import {
  getTarget,
  feedbackProgressMap,
  wait,
} from './utils'
import { useLocalStorage, useLocalStorageValue, useRemoveKeyLocalStorage } from 'hooks'
import { format, addDays } from 'date-fns'
import { useMilestoneTracker } from 'modules/FeedbackProvider'
import { useRouter } from 'next/router'
import Routes from 'types/routes'
import {
  FEEDBACK_VERSION,
  FEEDBACK_TIMEOUT_MS,
  FEEDBACK_TIMEOUT_WEEK,
} from './config'
import { getIsFeedbackEnabled } from 'utils/app'

const PAGE_MILESTONES = [Routes.Home, Routes.UsageInsights]
export const LOCAL_STORAGE_USER_FEEDBACK_KEY = 'feedback-data'
export const LOCAL_STORAGE_PAGE_MILESTONE_KEY = 'page-milestone'
export const FeedbackContext = createContext<FeedbackContextType | null>({
  CLOSED_AT: null,
  RESURFACE_AT: null,
  addMockFeedbackEntry: null,
  handleSolicitedFeedback: null,
  localFeedbackCache: null,
  setRecordDate: null,
  setRemoteCloseDate: null,
  setRemoteResurfaceDate: null,
  setRemoteWaitPeriod: null,
})

export const FeedbackProvider: FC<PropsWithChildren> = ({ children }) => {
  const [currentFeedbackStep, setCurrentFeedbackStep] = useState<FeedbackStep>(
    FeedbackSteps.RatingStep,
  )
  const { isValidSession } = useAuthentication()
  const [isSolicitedFeedback, setSolicitedFeedback] = useState(false)

  const postFeedback = async() => {
    const rating = parseInt(userFeedback.rating)
    const featureCompleteFeedback = parseFeatureCompleteFeedback(
      userFeedback.featureCompleteFeedback,
    )
    const negativeFeedback = parseFeedback(userFeedback.negativeFeedback)
    const positiveFeedback = parseFeedback(userFeedback.positiveFeedback)
    await Chai.submitSurveyFeedback(
      rating,
      featureCompleteFeedback,
      negativeFeedback,
      positiveFeedback,
    )
  }
  const parseFeatureCompleteFeedback = (feedback) => (feedback === 'yes' ? true : feedback === 'no' ? false : null)
  const parseFeedback = (feedback) => (feedback ? feedback : null)

  const { isLogSyncEnabled, gadgetsList } = useDeviceConnection()
  const userHasDevice = gadgetsList?.length > 0

  // launch Feedback
  const [shouldAskUserForFeedback, setShouldAskUserForFeedback] =
    useState<boolean>(false)


  const defaultUserFeedback = {
    closedOn: null,
    featureCompleteFeedback: null,
    negativeFeedback: null,
    positiveFeedback: null,
    rating: null,
  }

  const [userFeedback, setUserFeedback] =
    useState<FeedbackEntryCache>(defaultUserFeedback)

  const [localFeedbackCache, setLocalFeedbackCache] =
    useLocalStorage<FeedbackEntryCache[]>(
      LOCAL_STORAGE_USER_FEEDBACK_KEY,
      [],
    )

  const milestoneCache
    = useLocalStorageValue<PageChecklistType>(LOCAL_STORAGE_PAGE_MILESTONE_KEY)

  const router = useRouter()
  const milestoneTimers = useMilestoneTracker(PAGE_MILESTONES, router.pathname)

  const isDisabled = (): boolean => {
    if (currentFeedbackStep.name === FeedbackSteps.RatingStep.name) {
      return userFeedback[FeedbackCacheKeys.RATING] ? false : true
    } else if (currentFeedbackStep.name === FeedbackSteps.UserInputStep.name) {
      return !(userFeedback[FeedbackCacheKeys.NEGATIVE_FEEDBACK] ||
              userFeedback[FeedbackCacheKeys.POSITIVE_FEEDBACK])
    } else {
      return false
    }
  }

  const onMilestonePage = PAGE_MILESTONES.some((page) =>
    router.pathname === page)

  const isMilestoneReached = milestoneCache ? (
    Object.values(milestoneCache).every(page => page['milestoneReached'] === true)
  ) : false

  const isComplete = Object
    .values(feedbackProgressMap(localFeedbackCache))
    .every(Boolean)

  const isEmpty = Object
    .values(userFeedback)
    .every(
      value => value === null,
    )

  const isFirstTime = Object
    .values(feedbackProgressMap(localFeedbackCache))
    .every(
      step => !step,
    )

  const shouldResurface = userHasDevice &&
      !isComplete && !isFirstTime && onMilestonePage

  const isNewSurvey =
    FEEDBACK_VERSION !== localFeedbackCache
      ?.slice(-1)[0]
      ?.userFeedbackVersion

  const shouldSurveyUpdate = isNewSurvey
   && userHasDevice && onMilestonePage


  // Feedback Developer Tools
  const CLOSED_AT =
    localFeedbackCache
      ?.slice()
      .reverse()
      .find(
        (feedbackCache) =>
          feedbackCache.closedOn !== null && feedbackCache.isSolicited === false,
      )?.closedAt

  const RESURFACE_AT =
    localFeedbackCache
      ?.slice()
      .reverse()
      .find(
        (feedbackCache) =>
          feedbackCache.resurfaceAt !== null && feedbackCache.isSolicited === false,
      )?.resurfaceAt

  const addMockFeedbackEntry = () => {
    const date = new Date()
    const resurfaceDate = addDays(date, FEEDBACK_TIMEOUT_WEEK)

    setLocalFeedbackCache([
      ...localFeedbackCache,
      {
        closedAt: format(date, 'yyyy-MM-dd'),
        closedOn: 'RatingStep',
        date: format(date, 'yyyy-MM-dd'),
        featureCompleteFeedback: null,
        isSolicited: false,
        negativeFeedback: null,
        positiveFeedback: null,
        rating: null,
        resurfaceAt: format(resurfaceDate, 'yyyy-MM-dd'),
        userFeedbackVersion: FEEDBACK_VERSION,
      },
    ])

    return 'Mock Feedback Entry Added'
  }

  const setRemoteCloseDate = (closeDate: string) => {
    const date = format(new Date(closeDate.split('-').join()), 'yyyy-MM-dd')
    const clonedCache = localFeedbackCache.slice()
    const remoteItem = {
      ...clonedCache[clonedCache.length - 1],
      closedAt: date,
      ['date']: date,
      isDebug: true,
    }

    clonedCache.push(remoteItem)

    setLocalFeedbackCache(clonedCache)
  }

  const setRemoteWaitPeriod = (days: number) => {
    const clonedCache = localFeedbackCache.slice()
    const lastItem = clonedCache[clonedCache.length - 1]

    const date = new Date(lastItem.date)
    const resurfaceDate = addDays(date, days)

    const remoteItem = {
      ...clonedCache[clonedCache.length - 1],
      isDebug: true,
      resurfaceAt: format(resurfaceDate, 'yyyy-MM-dd'),
    }

    clonedCache.push(remoteItem)

    setLocalFeedbackCache(clonedCache)
  }

  const setRemoteResurfaceDate = (resurfaceDate: string) => {
    const date = format(new Date(resurfaceDate.split('-').join()), 'yyyy-MM-dd')
    const clonedCache = localFeedbackCache.slice()

    const remoteItem = {
      ...clonedCache[clonedCache.length - 1],
      isDebug: true,
      resurfaceAt: date,
    }

    clonedCache.push(remoteItem)

    setLocalFeedbackCache(clonedCache)
  }

  // Actions
  const handleLatestFeedbackStep = () => {
    const target = getTarget(localFeedbackCache)
    setCurrentFeedbackStep(FeedbackSteps[target])
  }

  const setRecordDate = () => {
    const date = new Date()
    const resurfaceDate = addDays(new Date(), FEEDBACK_TIMEOUT_WEEK)

    const feedbackData = {
      closedAt: format(date, 'yyyy-MM-dd'),
      closedOn: null,
      date: format(new Date(), 'yyyy-MM-dd'),
      featureCompleteFeedback: null,
      isSolicited: isSolicitedFeedback,
      negativeFeedback: null,
      positiveFeedback: null,
      rating: null,
      resurfaceAt: format(resurfaceDate, 'yyyy-MM-dd'),
    }

    // don't set if duplicate
    if (
      JSON.stringify(feedbackData) ===
        JSON.stringify(localFeedbackCache?.slice(-1)[0])
    ) {
      return
    }

    setLocalFeedbackCache([
      ...localFeedbackCache,
      {
        ...feedbackData,
        ...userFeedback,
        closedOn: currentFeedbackStep.name,
        userFeedbackVersion: FEEDBACK_VERSION,
      },
    ])

    if (!isEmpty) {
      postFeedback()
    }
  }

  /* close Feedback prompt */
  const closeFeedback = () => {

    /* user launched non-solicated feedback */
    if (isSolicitedFeedback === true) {
      setSolicitedFeedback(false)
      setRecordDate()

      return setShouldAskUserForFeedback(false)
    }

    /* record the data if in progress */
    if (!isComplete) {
      setRecordDate()
      return setShouldAskUserForFeedback(false)
    }
  }

  /* launch unsolicited Feedback prompt via Settings page */
  const handleSolicitedFeedback = () => {
    setUserFeedback(defaultUserFeedback)
    setShouldAskUserForFeedback(true)

    // clear radio button state
    const dialOptionRadio = document
      .querySelector('input[name=dial-options]:checked') as HTMLInputElement

    if (dialOptionRadio) {
      dialOptionRadio.checked = false
    }

    setSolicitedFeedback(true)
  }

  /* handle Feedback Card's primary action, the action progresses the wizard */
  const handlePrimaryAction = (primaryAction: FeedbackAction) => {
    if (primaryAction.target) {
      setCurrentFeedbackStep(FeedbackSteps[primaryAction.target])
    } else {
      closeFeedback()
    }
  }

  /* set feedback state on interaction within the feedback steps*/
  const handleRecordData: RecordDataCallback = (key: string, data: string) => {
    setUserFeedback({
      ...userFeedback,
      [key]: data,
    })
  }

  /* ran on step (FeatureCompleteStep) without primary button */
  const autoSubmitOnRecord: RecordDataCallback = (key, data) => {
    handleRecordData(key, data)
    handlePrimaryAction(currentFeedbackStep.primaryAction)
  }


  const startTimers = () => {
    if (
      userHasDevice &&
      PAGE_MILESTONES.includes(router.pathname as Routes) &&
      milestoneTimers[router.pathname]?.duration === 0) {
      milestoneTimers[router.pathname]?.startTimer()
    } else if (
      isLogSyncEnabled &&
        router.pathname == Routes.UsageInsights &&
        milestoneTimers[router.pathname]?.duration === 0) {
      milestoneTimers[router.pathname]?.startTimer()
    }
  }


  useEffect(() => {
    if (isNewSurvey) {
      setLocalFeedbackCache([])
    }
  }, [isNewSurvey])


  // Display feedback after milestones are met.
  useEffect(() => {
    // hide the Feedback Modal if the user is not logged in
    if (!isValidSession) {
      return setShouldAskUserForFeedback(false)
    }

    // hide the Feedback when info is gathered'
    if (!isFirstTime && isComplete && !isNewSurvey) {
      useRemoveKeyLocalStorage(LOCAL_STORAGE_PAGE_MILESTONE_KEY)()
      setUserFeedback(defaultUserFeedback)
      return setShouldAskUserForFeedback(false)
    }

    if (
      userHasDevice &&
      isMilestoneReached &&
      isFirstTime &&
      onMilestonePage
    ) {
      const timeout = wait(parseInt(FEEDBACK_TIMEOUT_MS), () =>
        setShouldAskUserForFeedback(true))

      return () => {
        milestoneTimers[router.pathname]?.pauseTimer()
        clearTimeout(timeout)
      }
    } else if (isFirstTime &&
      onMilestonePage) {
      return () => {
        milestoneTimers[router.pathname]?.pauseTimer()
      }
    }
  }, [
    isValidSession,
    userHasDevice,
    isComplete ? null : router.pathname,
    isMilestoneReached,
  ])

  // Re-surface Modal on resurfaceAt (7 days)
  useEffect(() => {
    startTimers()

    if (shouldResurface) {
      const resurfaceDate = new Date(
        localFeedbackCache?.slice(-1).pop()?.resurfaceAt,
      )

      const timeout = wait(resurfaceDate.valueOf() - Date.now().valueOf(), () =>
        setShouldAskUserForFeedback(true))

      return () => {
        milestoneTimers[router.pathname]?.pauseTimer()
        clearTimeout(timeout)
      }
    }
  }, [userHasDevice, router.pathname])

  // Handle Feedback cards
  useEffect(() => {
    // non-solicited feedback
    if (shouldSurveyUpdate || isFirstTime || isSolicitedFeedback) {
      setCurrentFeedbackStep(FeedbackSteps.RatingStep)

      return setUserFeedback({
        closedOn: null,
        featureCompleteFeedback: null,
        negativeFeedback: null,
        positiveFeedback: null,
        rating: null,
      })
    }

    // Handle latest resurface  only
    if (!isComplete) {
      return handleLatestFeedbackStep()
    }
  }, [userHasDevice, isFirstTime, localFeedbackCache, isSolicitedFeedback])

  const feedbackCardProps: FeedbackCardProps = {
    ...currentFeedbackStep?.props,
    handleRecordData: !currentFeedbackStep?.props.primaryButton
      ? autoSubmitOnRecord
      : handleRecordData,
    primaryButton: {
      disabled: isDisabled(),
      onClick: () => handlePrimaryAction(currentFeedbackStep?.primaryAction),
      ...currentFeedbackStep?.props.primaryButton,
    },
  }

  const values = {
    CLOSED_AT,
    RESURFACE_AT,
    addMockFeedbackEntry,
    handleSolicitedFeedback,
    localFeedbackCache,
    setRecordDate,
    setRemoteCloseDate,
    setRemoteResurfaceDate,
    setRemoteWaitPeriod,
  }

  return !getIsFeedbackEnabled() ? (
    <>
      {children}
    </>
  ) : (
    <FeedbackContext.Provider value={values}>
      {children}
      {shouldAskUserForFeedback && (
        <Drawer onClose={closeFeedback}>
          <FeedbackCard {...feedbackCardProps} />
        </Drawer>
      )}
    </FeedbackContext.Provider>
  )
}
