import React, { FC } from 'react'

import { Text } from 'components/Text'
import { Box } from 'components/Box'
import { Dial } from 'components/Dial'

import { RatingStepData } from '../../data'
import { FeedbackStepComponentProps, FeedbackCacheKeys } from '../../types'

import styles from './RatingStep.module.scss'

export const RatingStep: FC<FeedbackStepComponentProps> =
  ({ handleRecordData }) => {
    const { description, dial } = RatingStepData
    return (
      <Box className={styles.RatingStep}>
        <Text
          margin={{ bottom: 'baseLoose' }}
          className={styles.description}
          align='center'
          typography='bodyBase'
          message={description}
          tagName='p'
        />
        <Dial
          legend={dial.legend}
          onSelect={(e) => handleRecordData(FeedbackCacheKeys.RATING, e.target.value)}
          numberOfOptions={dial.numberOfOptions}
        />
      </Box>
    )
  }
