import { Reoverlay } from 'reoverlay'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
interface SetupPlaywright {
  openPlaywrightModal: () => void
}

export const modalData = {
  description: [{ content: 'Handy tool for setting up the environment before playwright tests.' }],
  heading: { content: 'Playwright Developer Tools' },
  primaryButton: { content: 'Disable Cloud Sync' },
  secondaryButton: { content: 'Unpair all devices' },
}

export const useSetupPlaywright = (): SetupPlaywright => {
  const {
    gadgetsList,
    toggleCloudSyncOptIn,
  } = useDeviceConnection()

  const disableCloudSync = () => {
    toggleCloudSyncOptIn(false)
  }

  const unpairAllDevices = () => {
    gadgetsList.map(gadget => gadget.unpair())
  }

  const openPlaywrightModal = () => Reoverlay.showModal('TextModal', {
    ...modalData,
    primaryButton: {
      ...modalData.primaryButton,
      onClick: disableCloudSync,
      primary: 'black',
    },
    secondaryButton: {
      ...modalData.secondaryButton,
      onClick: unpairAllDevices,
      primary: 'black',
    },
  })

  return { openPlaywrightModal }
}
