import { WebAppInsights } from 'analytics-events'

export const setPodHistoryDonutChartState = (
  hasPods: boolean,
  hasUnknownFlavor: boolean,
): WebAppInsights.PodHistoryDonutChartState => {
  if (!hasPods) {
    return WebAppInsights.PodHistoryDonutChartState.ZeroPods
  } else if (hasUnknownFlavor) {
    return WebAppInsights.PodHistoryDonutChartState.HasUnknownFlavor
  } else {
    return WebAppInsights.PodHistoryDonutChartState.Valid
  }
}

export const formatCount = (count: number): { pods: string, unit: string } => ({
  pods: count.toString(),
  unit: (count == 1) ? 'POD' : 'PODS',
})
