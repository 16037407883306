import { useState, useEffect } from 'react'
import { useLocalStorage, useInterval } from 'hooks'
import { PageChecklistType } from '../types'
import { LOCAL_STORAGE_PAGE_MILESTONE_KEY } from '../FeedbackProvider'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import Chai from 'types/chai'
import { getIsFeedbackEnabled } from 'utils/app'

const INTVL_SEC = parseInt(process.env.NEXT_PUBLIC_FEEDBACK_MILESTONE_TIMEOUT)

export const useMilestoneTracker = (
  milestones: string[],
  initialPage: string,
  defaultDuration = 0,
) => {
  const [isPaused, setIsPaused] = useState(true)
  const [page, setPage] = useState(initialPage)

  const { usageSyncState } = useDeviceConnection()

  const isDeviceSyncing = usageSyncState === Chai.UsageSyncState.SYNCING

  const timers = milestones.reduce((collection, page) => ({
    ...collection,
    [page]: {
      duration: defaultDuration,
      pauseTimer: () => {
        setPage(page)
        setIsPaused(true)
      },
      startTimer: () => {
        setPage(page)
        setIsPaused(false)
      },
    },
  }), {})

  const defaultMilestones = milestones.reduce((collection, page) => ({
    ...collection,
    [page]: {
      milestoneReached: false,
      timeSpent: 0,
    },
  }), {})

  const [milestoneCache, setPageMilestone]
    = useLocalStorage<PageChecklistType>(LOCAL_STORAGE_PAGE_MILESTONE_KEY, defaultMilestones)

  const isMilestoneReached = milestoneCache ? (
    Object.values(milestoneCache).every(page => page['milestoneReached'] === true)
  ) : false

  const areTimersDisabled =
    isDeviceSyncing ||
    !getIsFeedbackEnabled() ||
    !timers[page] ||
    milestoneCache?.[page].milestoneReached

  const handlePageMilestone = (page: string, timeElapsed: number) => {
    if (milestoneCache && milestoneCache[page]) {
      const timeSpent = milestoneCache[page].timeSpent + timeElapsed
      return setPageMilestone({
        ...milestoneCache,
        [page]: {
          milestoneReached: timeSpent >= INTVL_SEC ? true : false,
          timeSpent: timeSpent,
        },
      })
    }

    setPageMilestone({
      ...milestoneCache,
      [page]: {
        milestoneReached: timeElapsed >= INTVL_SEC ? true : false,
        timeSpent: timeElapsed,
      },
    })

  }

  useInterval(()=>{
    if (areTimersDisabled) {
      return
    }

    timers[page].duration = timers[page].duration + 1
    handlePageMilestone(page, timers[page].duration)
  }, !isPaused ? 1000 : null)


  useEffect(() => {
    if (isMilestoneReached) {
      setIsPaused(true)
    }
  }, [isMilestoneReached])

  return timers
}
