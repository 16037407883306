import FeedbackSteps from './data'
import { FeedbackEntryCache } from './types'

export const isEmptyString = (str) => !str.trim().length

export const getTarget = (feedbackCache: FeedbackEntryCache[]) => {
  const closedOn =
  feedbackCache
    ?.slice()
    .reverse()
    .find(
      (feedbackCache) =>
        feedbackCache.closedOn !== null && feedbackCache.isSolicited === false,
    )?.closedOn || 'RatingStep'

  const stepLogicMap = {
    FeatureCompleteStep: 'featureCompleteFeedback',
    RatingStep: 'rating',
    UserInputStep: ['negativeFeedback', 'positiveFeedback'],
  }

  if (closedOn === 'UserInputStep' || closedOn === 'ThankYouStep') {


    const isComplete = Boolean(feedbackCache.find(
      (feedback) =>
        feedback?.negativeFeedback &&
          !isEmptyString(feedback?.negativeFeedback) &&
          !feedback?.isSolicited,
    )) || Boolean(
      feedbackCache.find(
        (feedback) =>
          feedback?.positiveFeedback &&
          !isEmptyString(feedback?.positiveFeedback) &&
          !feedback?.isSolicited,
      ),
    )

    return isComplete ? FeedbackSteps[closedOn]?.primaryAction?.target : 'UserInputStep'
  }

  const target = FeedbackSteps[closedOn]?.primaryAction?.target

  return feedbackProgressMap(feedbackCache)[stepLogicMap[closedOn]] ? target : closedOn
}

export const feedbackProgressMap = (
  feedbackCache: FeedbackEntryCache[],
) =>({
  featureCompleteFeedback: Boolean(
    feedbackCache?.find(
      (feedback) => feedback?.featureCompleteFeedback &&
      !feedback?.isSolicited,
    ),
  ),
  rating: Boolean(
    feedbackCache?.find(
      (feedback) => feedback?.rating &&
        !feedback?.isSolicited,
    ),
  ),
  userInputFeedback: Boolean(
    feedbackCache?.find(
      (feedback) =>
        feedback?.negativeFeedback &&
          !isEmptyString(feedback?.negativeFeedback) &&
          !feedback?.isSolicited,
    ) ||
    feedbackCache?.find(
      (feedback) =>
        feedback?.positiveFeedback &&
          !isEmptyString(feedback?.positiveFeedback) &&
          !feedback?.isSolicited,
    ),
  ),

})

export const wait = (timeout: number, callback: (args?: any) => any) =>
  setTimeout(() => {
    callback()
  }, timeout)

export default { feedbackProgressMap, getTarget,isEmptyString, wait }
