import React, { FC } from 'react'

import { Icon } from '@juullabs/react-components'
import { Box } from 'components/Box'
import { Text } from 'components/Text'
import { Textarea } from 'components/Textarea'

import { UserInputStepData } from '../../data'
import { FeedbackStepComponentProps } from '../../types'

import styles from './UserInputStep.module.scss'

export const UserInputStep: FC<FeedbackStepComponentProps> = (
  { handleRecordData },
) => {
  const { forms, maxLength } = UserInputStepData

  return (
    <Box
      className={styles.UserInputStep}
      padding={{ left: 'base', right: 'base' }}
    >
      {forms.map((form) => (
        <Box key={form.name} className={styles.form}>
          <Box margin={{ right: 'tight' }}>
            <Icon className={styles.icon} {...form.icon} />
          </Box>
          <Textarea
            maxLength={maxLength}
            className={styles.textareaWrapper}
            textareaClassName={styles.textareaInput}
            label={(
              <Text
                color='ink|base|50'
                message={form.message}
                type='inline'
                typography='bodySmall'
              />
            )}
            name={form.name}
            onChange={(e) => handleRecordData(form.name, e.target.value)}
          />
        </Box>
      ))}
    </Box>
  )
}
