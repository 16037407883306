import React, { FC } from 'react'
import styles from './DropdownButton.module.scss'
import { Button } from 'components/Button'
import { UnstyledLink, UnstyledButton } from 'components/UnstyledPrimitives'
import { DropdownButtonProps, LinkOption, ButtonOption } from './types'
import { classNames } from 'utils/css'
import { useToggle } from 'hooks'

export const DropdownButton: FC<DropdownButtonProps> = ({
  className,
  dropdownClassName,
  options,
  ...rest
}) => {
  const [isOpen, toggleIsOpen] = useToggle(false)

  const getDropdownActionHandler = (action: () => void) => (
    () => {
      toggleIsOpen()
      action && action()
    }
  )

  return (
    <div className={classNames(styles.DropdownButton, className)}>
      <Button
        onClick={toggleIsOpen}
        icon={isOpen ? 'icon/24x24/remove' : 'icon/24x24/add'}
        alignIcon='right'
        justifyContent='spaceBetween'
        {...rest}
      />
      {isOpen && (
        <ul className={classNames(styles.dropdownContent, dropdownClassName)}>
          {options.map(({ href = null, label, onClick, ...rest }, i) => (
            <li key={'dropdown-option-' + i}>
              {href ? (
                <UnstyledLink
                  aria-label={label}
                  className={styles.dropdownOption}
                  onClick={getDropdownActionHandler(onClick)}
                  href={href}
                  {...rest as LinkOption}
                >
                  <div className={styles.optionLabel}>
                    {label}
                  </div>
                </UnstyledLink>
              ) : (
                <UnstyledButton
                  aria-label={label}
                  className={styles.dropdownOption}
                  onClick={getDropdownActionHandler(onClick)}
                  {...rest as ButtonOption}
                >
                  <div className={styles.optionLabel}>
                    {label}
                  </div>
                </UnstyledButton>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
