import React, { FC } from 'react'

import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { Box } from 'components/Box'

import { FeatureCompleteStepData } from '../../data'
import { FeedbackStepComponentProps, FeedbackCacheKeys } from '../../types'

import styles from './FeatureCompleteStep.module.scss'

export const FeatureCompleteStep: FC<FeedbackStepComponentProps> = ({ handleRecordData }) => {
  const { description, options } = FeatureCompleteStepData

  return (
    <Box
      className={styles.FeatureCompleteStep}
      padding={{ bottom: 'loose', left: 'base', right: 'base' }}
    >
      <Text
        margin={{ bottom: 'loose' }}
        className={styles.description}
        align='center'
        typography='bodyBase'
        message={description}
        tagName='p'
      />
      <Box className={styles.options}>
        {options.map((option) => (
          <Button
            key={'option-' + option}
            onClick={() =>
              handleRecordData(
                FeedbackCacheKeys.FEATURE_COMPLETE_FEEDBACK,
                option,
              )
            }
            content={option}
            size='base'
            type='button'
            fullWidth
            primary='black'
          />
        ))}
      </Box>
    </Box>
  )
}
