import {
  HTMLAttributes,
  FC,
  ComponentProps,
  ComponentPropsWithoutRef,
} from 'react'
import {
  WizardStep,
  WizardStepAction,
  WizardCardProps,
} from 'types/wizard'
import { TextFormats } from 'components/Text'
import { Icon } from '@juullabs/react-components'

export type FeedbackContextType = {
  CLOSED_AT: string
  RESURFACE_AT: string
  addMockFeedbackEntry: () => void
  handleSolicitedFeedback: () => void
  localFeedbackCache: FeedbackEntryCache[]
  setRecordDate: (closedOn?: string, FeedbackDeveloperToolsDate?: string) => void
  setRemoteCloseDate: (date: string) => void
  setRemoteResurfaceDate: (date: string) => void
  setRemoteWaitPeriod: (days: number) => void
}

export type Timer = {
  [key: string]: {
    duration: number
    pauseTimer: () => void
    startTimer: () => void
  }
}

export type FeedbackStepName =
  | 'FeatureCompleteStep'
  | 'UserInputStep'
  | 'RatingStep'
  | 'ThankYouStep';

export enum FeedbackCacheKeys {
  CLOSED_AT = 'closedAt',
  CLOSED_ON = 'closedOn',
  DATE = 'date',
  FEATURE_COMPLETE_FEEDBACK = 'featureCompleteFeedback',
  IS_SOLICITED = 'isSolicited',
  NEGATIVE_FEEDBACK = 'negativeFeedback',
  POSITIVE_FEEDBACK = 'positiveFeedback',
  RATING = 'rating',
  RESURFACE_AT = 'resurfaceAt',
  USER_FEEDBACK_VERSION = 'userFeedbackVersion'
}

export type FeedbackEntryCache = {
  closedAt?: string
  closedOn: string
  date?: string
  featureCompleteFeedback: string
  isSolicited?: boolean
  negativeFeedback: string
  positiveFeedback: string
  rating: string
  resurfaceAt?: string
  userFeedbackVersion?: string
}

export type FeedbackAction = WizardStepAction<
  FeedbackStepName,
  null
>;

export type RecordDataCallback = (key: string, data: any) => void

export interface FeedbackStepComponentProps extends ComponentPropsWithoutRef<'div'> {
  handleRecordData: RecordDataCallback
}

export interface FeedbackCardProps extends HTMLAttributes<HTMLDivElement>,
  WizardCardProps<null> {
  component: FC<FeedbackStepComponentProps>
  handleRecordData?: RecordDataCallback
}

export type FeedbackStep = WizardStep<
  FeedbackStepName,
  null,
  FeedbackCardProps,
  FeedbackAction
>

export type FeedbackSteps = Record<FeedbackStepName, FeedbackStep>

export type FeatureCompleteStepType = {
  description: TextFormats
  options: string[]
};

export type RatingStepType = {
  description: TextFormats
  dial: {
    legend: string[]
    numberOfOptions: number
  }
};

type FormInputType = {
  icon: ComponentProps<typeof Icon>
  message: TextFormats
  name: FeedbackCacheKeys
};

export type UserInputStepType = {
  forms: FormInputType[]
  maxLength: number
};

export type ThankYouStepType = {
  description: TextFormats
  icon: ComponentProps<typeof Icon>
  title: TextFormats
};

export interface RecordTimeType {
  [dateName: string]: Date
}

export type RecordDataType = {
  FeatureCompleteStep?: {
    data: string
  }
  RatingStep?: {
    data: string
  }
  UserInputStep?: {
    data: {
      negativeFeedback: {currentData: string}
      positiveFeedback: {currentData: string}
    }
  }
};

export interface PageChecklistType {
  [pageName: string]: {
    milestoneReached: boolean
    timeSpent: number
  }
}
