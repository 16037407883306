import React, { FC } from 'react'

import { Box } from 'components/Box'
import { Button } from 'components/Button'

import { FeedbackCardProps } from '../../types'

export const FeedbackCard: FC<FeedbackCardProps> = ({
  component: FeedbackComponent,
  primaryButton,
  handleRecordData,
}) => (
  <Box padding={{ bottom: 'base', left: 'base', right: 'base', top: 'tight' }}>
    <FeedbackComponent handleRecordData={handleRecordData} />
    {primaryButton?.content && (
      <Box align='right' margin={{ top: 'loose' }}>
        <Button
          disabled={primaryButton?.disabled}
          accessibilityLabel={primaryButton?.content}
          content={primaryButton?.content}
          onClick={primaryButton?.onClick}
          size='small'
          type='button'
          primary='black'
        />
      </Box>
    )}
  </Box>
)
