import React, { FC, useMemo } from 'react'
import styles from './UsageInsights.module.scss'
import { classNames } from 'utils/css'
import { InsightsSection } from './components'
import { MockPuffs } from './mocks'
import {
  ActivityUpdates,
  AveragePuffDay,
  AveragePuffHour,
  BatteryConsumption,
  PodConsumption,
  PuffHistory,
  PuffsSoFar,
  PodHistory,
} from './insights'
import {
  InsightsUnavailable,
  KeepYourInsightsNotice,
  PuffAlgoUpdateNotice,
} from './notices'
import { FlexColumn } from 'components/FlexColumn'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { useLocalStorage } from 'hooks'
import { GridItem, Page } from 'modules/Layout/components'
import {
  UI_PREFERENCES,
  UIPreferences,
  UIKeys,
  initialUIPreferencesCache,
  toggleUIEvents,
} from './config'
import { getIsActivityUpdatesEnabled } from './utils'
import { getIsPreProdEnvironment } from 'utils/app'
import { WebAppScreens, Event } from 'analytics-events'

export const UsageInsights: FC = () => {
  const { usageInsightsState, isUsageInsightsReady } = useDeviceConnection()
  const insightsLoading = !isUsageInsightsReady
  const {
    daysTillWeek,
    hasWeekOfData,
    showLongTermTrend,
    showShortTermTrend,
  } = usageInsightsState || {}

  const [
    UIPreferencesCache,
    setUIPreferencesCache,
  ] = useLocalStorage<UIPreferences>(UI_PREFERENCES, initialUIPreferencesCache)

  const toggleUIPreference = (insightKey: UIKeys) => {
    setUIPreferencesCache({
      ...UIPreferencesCache,
      [insightKey]: !UIPreferencesCache[insightKey],
    })
  }

  const getInsightProps = (insightKey: UIKeys) => {
    const isHidden = UIPreferencesCache[insightKey]

    return {
      isHidden,
      loading: insightsLoading,
      toggleButton: {
        clickEvent: toggleUIEvents[insightKey]({ enabled: isHidden }),
        onClick: () => toggleUIPreference(insightKey),
      },
    }
  }

  /* XNOR boolean to determine if we see pod and battery consumption insights
    in a row or column. */
  const showEstimatesInRow = UIPreferencesCache[UIKeys.BatteryConsumption]
    == UIPreferencesCache[UIKeys.PodConsumption]

  const showActivityUpdates = getIsActivityUpdatesEnabled()
    && (showLongTermTrend || showShortTermTrend)

  // function to check and return activity update state
  const getActivityUpdatesState = () => {
    if (!showActivityUpdates) {
      return WebAppScreens.ActivityUpdatesState.NoActivityUpdates
    } else if (showShortTermTrend && showLongTermTrend) {
      return WebAppScreens.ActivityUpdatesState.BothActivityUpdates
    } else if (showShortTermTrend) {
      return WebAppScreens.ActivityUpdatesState.ShortTermUpdatesOnly
    } else {
      return WebAppScreens.ActivityUpdatesState.LongTermUpdatesOnly
    }
  }

  // events
  const screenEvent = useMemo<Event>(() => {
    if (!isUsageInsightsReady) return null

    return WebAppScreens.UsageInsightsEvent({
      activityUpdatesState: getActivityUpdatesState(),
      usageInsightsState: hasWeekOfData
        ? WebAppScreens.UsageInsightsState.HasFirstWeek
        : WebAppScreens.UsageInsightsState.NoFirstWeek,
    })
  }, [isUsageInsightsReady, hasWeekOfData, showShortTermTrend, showLongTermTrend])

  return (
    <GridItem>
      <Page className={styles.UsageInsights} screenEvent={screenEvent}>
        <FlexColumn spacing={{ default: 'loose', tablet: 'extraLoose' }}>
          {isUsageInsightsReady && !hasWeekOfData && (
            <InsightsUnavailable daysTillAvailable={daysTillWeek} />
          )}
          <FlexColumn spacing={{ default: 'baseTight' }}>
            {isUsageInsightsReady && hasWeekOfData && <KeepYourInsightsNotice />}
            <PuffAlgoUpdateNotice />
          </FlexColumn>
          {showActivityUpdates && (
            <ActivityUpdates
              showLongTermTrend={showLongTermTrend}
              showShortTermTrend={showShortTermTrend}
              {...getInsightProps(UIKeys.ActivityUpdates)}
            />
          )}
          <InsightsSection title='Usage Insights'>
            <FlexColumn spacing={{ default: 'base', tablet: 'baseLoose' }}>
              <PuffsSoFar {...getInsightProps(UIKeys.PuffsSoFar)} />
              {hasWeekOfData && (
                <div className={classNames(
                  styles.estimates,
                  showEstimatesInRow && styles.row,
                )}>
                  <BatteryConsumption {...getInsightProps(UIKeys.BatteryConsumption)} />
                  <PodConsumption {...getInsightProps(UIKeys.PodConsumption)} />
                </div>
              )}
              <PodHistory {...getInsightProps(UIKeys.PodHistory)} />
              {hasWeekOfData && (
                <AveragePuffHour {...getInsightProps(UIKeys.AveragePuffHour)} />
              )}
              {hasWeekOfData && (
                <AveragePuffDay {...getInsightProps(UIKeys.AveragePuffDay)} />
              )}
              <PuffHistory {...getInsightProps(UIKeys.PuffHistory)} />
              {getIsPreProdEnvironment() && <MockPuffs/>}
            </FlexColumn>
          </InsightsSection>
        </FlexColumn>
      </Page>
    </GridItem>
  )
}
