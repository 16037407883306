import React, { FC, HTMLAttributes } from 'react'
import { classNames } from 'utils/css'
import styles from './Dial.module.scss'

export interface DialProps extends HTMLAttributes<HTMLFieldSetElement> {
  /** additional styles */
  className?: string
  /** text for an error */
  errorText?: string
  /** array of labels to be displayed evenly left to right */
  legend?: string[]
  /** number of buttons */
  numberOfOptions: number
  /** onClick to select a button */
  onSelect(event): void
}

export const Dial: FC<DialProps> = ({
  className,
  errorText,
  legend,
  numberOfOptions,
  onSelect,
  ...rest
}) => (
  <fieldset className={classNames(styles.Dial, className)} {...rest}>
    {legend && (
      <div className={styles.legend}>
        {legend.map((label, i) => (
          <div key={'legend-' + i}>{label}</div>
        ))}
      </div>
    )}
    <div className={styles.options}>
      {Array.from({ length: numberOfOptions }, (_, i) => i+1)
        .map((optionValue) => (
          <div className={styles.option} key={'option-' + optionValue}>
            <input
              className={styles.optionInput}
              id={`dial-${optionValue}`}
              name='dial-options'
              onClick={onSelect}
              type='radio'
              value={optionValue}
            />
            <label
              className={styles.optionLabel}
              htmlFor={`dial-${optionValue}`}
            >
              {optionValue}
            </label>
          </div>
        ))}
    </div>
    {errorText && (
      <div className={styles.errorText}>
        {errorText}
      </div>
    )}
  </fieldset>
)
