import React, { FC, useState, useMemo } from 'react'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { Box } from 'components/Box'
import { InsightsCard, InsightsCardProps } from '../../components'
import { SlidingTabs } from 'components/SlidingTabs'
import {
  PodHistoryDonut,
  PodHistoryPeriod,
  setPodHistoryDonutChartState,
} from '../../charts/PodHistoryDonut'
import { WebAppInsights, Event } from 'analytics-events'

// config
const POD_HISTORY_CHART_MAX_DIAMETER = 360

// data
export const podHistoryTitle = 'Pod History'
const AVAILABLE_PERIODS: PodHistoryPeriod[] = [
  PodHistoryPeriod.SevenDays,
  PodHistoryPeriod.ThirtyDays,
  PodHistoryPeriod.NinetyDays,
]

// types
export type PodHistoryProps = Omit<InsightsCardProps, 'title'>

export const PodHistory: FC<PodHistoryProps> = ({ ...rest }) => {
  // context
  const { trackClickEvent } = useAnalytics()

  // state
  const [hasPods, setHasPods] = useState<boolean>(null)
  const [hasUnknownFlavor, setHasUnknownFlavor] = useState<boolean>(null)
  const [
    timePeriod,
    setTimePeriod,
  ] = useState<PodHistoryPeriod>(PodHistoryPeriod.SevenDays)

  // legacy events
  const trackTimePeriod = (value) => {
    // This is sensitive to the PodHistoryPeriod type and will need to be changed with it.
    const trackedValue = value.split(' ')[0] + 'Days'
    trackClickEvent({
      screenEvent: 'UsageMonitor',
      viewClicked: `PodHistoryChart|${trackedValue}`,
    })
    setTimePeriod(value)
  }

  // events
  const podHistoryEvent = useMemo<Event>(() => {
    if (hasPods === null || hasUnknownFlavor === null) return null

    return WebAppInsights.PodHistoryEvent({
      chartState: setPodHistoryDonutChartState(hasPods, hasUnknownFlavor),
      isValid: true,
    })
  }, [hasPods, hasUnknownFlavor])

  const podHistoryControls = (
    <Box padding={{ top: 'tight' }}>
      <SlidingTabs
        id='podHistory'
        onClickValue={trackTimePeriod}
        values={AVAILABLE_PERIODS}
      />
    </Box>
  )

  return (
    <InsightsCard
      cardEvent={podHistoryEvent}
      controls={podHistoryControls}
      title={podHistoryTitle}
      {...rest}
    >
      <PodHistoryDonut
        padding={{ top: 'baseLoose' }}
        maxDiameter={POD_HISTORY_CHART_MAX_DIAMETER}
        setHasPods={setHasPods}
        setHasUnknownFlavor={setHasUnknownFlavor}
        timePeriod={timePeriod}
      />
    </InsightsCard>
  )
}
