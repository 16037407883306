import React, { FC, useState, useMemo } from 'react'
import { InsightsCard, InsightsCardProps } from '../../components/InsightsCard'
import { body, formatBody, title } from './localization'
import { Text } from 'components/Text'
import { HourlyPuffBarchart } from '../../charts/HourlyPuffBarchart'
import { WebAppInsights, Event } from 'analytics-events'

// config
const BARCHART_HEIGHT = 240

// types
export type AveragePuffHourProps = Omit<InsightsCardProps, 'title'>

export const AveragePuffHour: FC<AveragePuffHourProps> = ({
  loading,
  ...rest
}) => {
  // state
  const [isValid, setIsValid] = useState<boolean>(null)
  const [minPuffHour, setMinPuffHour] = useState<number>(null)
  const [maxPuffHour, setMaxPuffHour] = useState<number>(null)

  // variables
  const isConstant = useMemo<boolean>(() => {
    if (minPuffHour === null || maxPuffHour === null) return null
    return minPuffHour === maxPuffHour
  }, [minPuffHour, maxPuffHour])

  // events
  const averagePuffHourEvent = useMemo<Event>(() => {
    if (isValid === null || isConstant === null) return null
    return WebAppInsights.AveragePuffHoursEvent({
      chartState: isConstant
        ? WebAppInsights.AveragePuffHoursBarChartState.SameHighestLowest
        : WebAppInsights.AveragePuffHoursBarChartState.Valid,
      isValid: isValid,
    })
  }, [isValid, isConstant])

  return (
    <InsightsCard
      cardEvent={averagePuffHourEvent}
      isValid={isValid}
      title={title}
      loading={loading || isValid === null}
      {...rest}
    >
      {minPuffHour != null && maxPuffHour != null && (
        <Text
          message={body}
          tagName='p'
          typography='bodyBig'
          values={formatBody(minPuffHour, maxPuffHour)}
        />
      )}
      <HourlyPuffBarchart
        height={BARCHART_HEIGHT}
        margin={{ top: 'baseLoose' }}
        setIsValid={setIsValid}
        setMaxPuffHour={setMaxPuffHour}
        setMinPuffHour={setMinPuffHour}
      />
    </InsightsCard>
  )
}
