import React, { createContext, FC, PropsWithChildren, useEffect, useState } from 'react'
import { signOut as nextAuthSignOut, SignOutParams, useSession } from 'next-auth/react'
import Chai from 'types/chai'
import { GrantInfo } from 'types/user'

import { useAnalytics } from 'modules/AnalyticsProvider'
import { bugsnagClient } from 'utils/bugsnag'

import {
  clearAuthToken as clearApiClientSession,
  setAuthToken as setApiClientSession,
  uploadOAuthKey,
} from 'modules/MobilecloudAPI'
import { useConxSdk } from 'modules/ConxSdkProvider'
import {
  AuthCredentials,
  AuthenticationContextType,
  OAUTH_SESSION_FAILED_KEY,
} from './types'
import { LoginRedirection } from '@juullabs/analytics-multiplatform'
import { useRouter } from 'next/router'
import Routes from 'types/routes'
import { AuthErrorCode } from 'modules/Welcome/localization'

const MAX_HANDSHAKE_ATTEMPTS = 3

export const AuthenticationContext =
  createContext<AuthenticationContextType | null>({
    authCredentials: null,
    authErrorCode: null,
    isSessionLoading: true,
    isValidSession: false,
    oauthSessionId: null,
    onOauthSessionFailureRetry: null,
    signOut: null,
    userSession: null,
  })

export const AuthenticationProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    analytics,
    analyticsIdentifyReset,
    trackEvent,
    trackSignIn,
  } = useAnalytics()

  const { sdkContext, userClientId } = useConxSdk()

  const { push: routerPush, query, replace: replaceHistory } = useRouter()
  const error = query?.error as string

  const {
    data: nextAuthSession,
    status: nextAuthSessionStatus,
  } = useSession()

  const loading = nextAuthSessionStatus === 'loading'

  /** INTERNAL STATE */

  const [authError, setAuthError] = useState<string>(null)
  const [authCredentials, setAuthCredentials] = useState<AuthCredentials | null>(null)
  const [isValidSession, setIsValidSession] = useState<boolean>(false)
  const [isSessionLoading, setIsSessionLoading] = useState<boolean>(true)
  const [oauthSessionId, setOauthSessionId] = useState<string>()
  const [handshakeAttemptCounter, setHandshakeAttemptCounter] = useState<number>(0)
  const [sdkUserSession, setSdkUserSession] = useState<Chai.UserSession>()

  /** HOOKS */

  const generateSessionId = async(): Promise<string> => {
    setIsSessionLoading(true)

    // Prime the SSO flow by generating a key-pair and posting the public key to the backend.
    const publicKey = await sdkUserSession.getOAuthKey()
    return uploadOAuthKey({ public_key: publicKey })
  }

  const onOauthSessionFailureRetry = () => {
    authError === AuthErrorCode.NetworkError && setAuthError(null)
    setHandshakeAttemptCounter(0)
  }

  /**
   * Initialize an authenticated SDK User Session context
   *
   * @returns {Promise<boolean>}
   */
  const beginUserSession = async(grantInfo: GrantInfo): Promise<boolean> => {
    const {
      accessToken,
      guid,
      keychain,
    } = grantInfo

    setApiClientSession(accessToken)
    setAuthCredentials({
      ...grantInfo,
      userAccessToken: accessToken,
    })

    try {
      await sdkUserSession.setCredentials(accessToken, keychain)
      trackSignIn(guid)
      setIsValidSession(true)
      setIsSessionLoading(false)

      return true
    } catch (e) {
      bugsnagClient?.notify?.({
        message: `Failed to begin user session with keychain for user: ${e}`,
        name: 'user_keychain_failed',
      })

      // In rare cases we can see things get out of sync with keychain handling.
      // To be safe, we invalidate the session at this point so a fresh one can be acquired.
      await signOut()

      return false
    }
  }

  const signOut = async(
    params: SignOutParams<boolean> = { callbackUrl: Routes.Welcome, redirect: false },
  ) => {
    setIsValidSession(false) // sets loading screen
    setIsSessionLoading(true)
    clearApiClientSession()
    setAuthCredentials(null)
    analyticsIdentifyReset()
    await sdkUserSession?.clearCredentials()
    const data = await nextAuthSignOut(params)
    await routerPush(data?.url || Routes.Welcome)
    return data
  }

  const handleLoginError = (error: string) => {
    setAuthError(error)

    // Clear the error from the URL
    replaceHistory(Routes.Welcome)

    trackEvent(
      LoginRedirection.FailedEvent({ error }),
    )
  }

  /** EFFECTS */

  /**
   * Populate the SDK user session once the user-client ID is available.
   */
  useEffect(() => {
    if (sdkUserSession || !userClientId || !sdkContext) return

    const newSdkUserSession = new Chai.UserSession(sdkContext)
    setSdkUserSession(newSdkUserSession)
  }, [sdkContext, sdkUserSession, userClientId])

  /**
   * Handle auth errors.
   */
  useEffect(() => {
    if (!error || !analytics) return

    handleLoginError(error)
  }, [analytics, error])

  /**
   * Handle secure session initialization, and session tear-down if the browser cache is cleared.
   */
  useEffect(() => {
    if (loading || !sdkUserSession) return

    const { grantInfo } = nextAuthSession || {}
    const isSessionAccessTokenPresent = Boolean(grantInfo?.accessToken)
    if (isSessionAccessTokenPresent && !authCredentials?.userAccessToken) {
      beginUserSession(grantInfo)
    } else if (!isSessionAccessTokenPresent && authCredentials?.userAccessToken) {
      // Reset user session state to reflect the next-auth session having been cleared
      // while we have auth credentials in-state (e.g., local storage/cache cleared).
      signOut()
    }
  }, [nextAuthSession?.grantInfo, loading, sdkUserSession])

  /**
   * Handle session initialization with the SDK.
   */
  useEffect(() => {
    if (
      !sdkUserSession
      || sdkUserSession.isActive
      || loading
      || nextAuthSession
      || oauthSessionId
    ) return

    if (handshakeAttemptCounter >= MAX_HANDSHAKE_ATTEMPTS) {
      // Too many attempts, likely due to a bad connection. Alert the user at this point
      // that they should find better reception.
      setAuthError(AuthErrorCode.NetworkError)
      trackEvent({
        name: OAUTH_SESSION_FAILED_KEY,
        properties: { attempts: handshakeAttemptCounter },
      })

      setIsSessionLoading(false)
      return
    }

    generateSessionId().then((sessionId) => {
      setOauthSessionId(sessionId)
    }).catch((error) => {
      const statusCode = error?.response?.status
      if (!statusCode || statusCode >= 502) {
        // No response or a status of 502 or above indicates difficulty reaching the backend.
        // Increment the attempt counter and try again.
        setHandshakeAttemptCounter((prev) => prev + 1)
        return
      }

      // Else something went wrong in the session transaction and we likely can't recover from it.
      setIsSessionLoading(false)
      setOauthSessionId(OAUTH_SESSION_FAILED_KEY)
      bugsnagClient?.notify?.(error)
    })
  }, [
    handshakeAttemptCounter,
    isValidSession,
    loading,
    nextAuthSession,
    oauthSessionId,
    sdkUserSession,
    sdkUserSession?.isActive,
  ])

  /**
   * Handle session invalidation based on the SDK user session state relative to
   * this provider's state.
   */
  useEffect(() => {
    if (
      !sdkUserSession
      || !sdkContext
      || !isValidSession
      || !nextAuthSession
      || sdkUserSession.isSessionActive
    ) return

    // The SDK will invalidate sessions based on backend responses.
    signOut({
      callbackUrl: `${Routes.Welcome}?error=${AuthErrorCode.SessionExpired}`,
      redirect: true,
    })
  }, [
    isValidSession,
    nextAuthSession,
    sdkContext,
    sdkUserSession,
    sdkUserSession?.isActive,
    sdkUserSession?.isSessionActive,
  ])

  /**
   * Disable the loading placeholder only after the session ID has propagated through state.
   */
  useEffect(() => {
    if (!oauthSessionId && authError !== AuthErrorCode.NetworkError) return

    setIsSessionLoading(false)
  }, [authError, oauthSessionId])

  // Setup the device connection context interface
  const values: AuthenticationContextType = {
    authCredentials,
    authErrorCode: authError,
    isSessionLoading,
    isValidSession,
    oauthSessionId,
    onOauthSessionFailureRetry,
    signOut,
    userSession: sdkUserSession,
  }

  // Finally, return the interface that we want to expose to our other components
  return (
    <AuthenticationContext.Provider value={values}>
      {children}
    </AuthenticationContext.Provider>
  )
}
