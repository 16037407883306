import React, { FC } from 'react'
import { Reoverlay, ModalContainer } from 'reoverlay'

import { Modal } from './components/Modal'
import { TextModal } from './components/TextModal'

Reoverlay.config([
  { component: Modal, name: 'Modal' },
  { component: TextModal, name: 'TextModal' },
])

export const ReoverlayModal: FC = () => <ModalContainer />
