import Chai from 'types/chai'
import { bugsnagClient } from 'utils/bugsnag'
import { getAppVersion, getDataDogBuildType, getIsDebugMode, getIsPreProdEnvironment } from 'utils/app'

export const USER_CLIENT_ID_KEY = 'conx-user-client-id'

export const deviceCloudProxy = process.env.NEXT_PUBLIC_DEVICE_CLOUD_PROXY_ENV
  ? new Chai.DeviceCloudProxy(
    process.env.NEXT_PUBLIC_DEVICE_CLOUD_PROXY_ENV,
    process.env.NEXT_PUBLIC_DEVICE_CLOUD_PROXY_URL || null,
  )
  : undefined

const firebaseConfig: Chai.FirebaseConfig = process.env.NEXT_PUBLIC_FIREBASE_API_KEY
  ? {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    authDomain: null,
    messagingSenderId: null,
    messagingVapidKey: null,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: null,
  }
  : undefined

const environmentOverrides = (): Chai.Overrides => {
  const overrides = new Chai.Overrides()
  overrides.fakeMode = getIsDebugMode()
  overrides.unlinkExistingUser = process.env.NEXT_PUBLIC_CHAI_UNLINK_EXISTING_USER === 'true'
  overrides.mobileCloudHost = process.env.NEXT_PUBLIC_MOBILECLOUD_URL
  overrides.runtimeIoAccount = process.env.NEXT_PUBLIC_RUNTIME_IO_ACCOUNT
  overrides.logLevel = process.env.NEXT_PUBLIC_CHAI_LOG_LEVEL
  overrides.encryptedUsageSync = process.env.NEXT_PUBLIC_ENCRYPTION_ENABLED !== 'false'
  overrides.deviceCloudProxy = deviceCloudProxy
  overrides.sendAllLogsToDatadog = process.env.NEXT_PUBLIC_SEND_LOGCAT_TO_ANALYTICS === 'true'
  overrides.mutableRemoteConfig = getIsPreProdEnvironment()
  return overrides
}

export const ChaiConfig = {
  appName: 'conx-web',
  appVersion: getAppVersion(),
  buildType: getDataDogBuildType(),
  cloudApiCompatibilityVersion: BigInt(1),
  overrides: environmentOverrides(),
}

export const newChaiContext = (uid: string, config: typeof ChaiConfig) => {
  const contextConfig = new Chai.ContextConfig()
  contextConfig.clientId = uid
  contextConfig.appName = config.appName
  contextConfig.appVersion = config.appVersion
  contextConfig.appVersionBuildCode = config.cloudApiCompatibilityVersion
  contextConfig.localization = null // TODO localization
  contextConfig.overrides = config.overrides
  contextConfig.buildType = config.buildType
  contextConfig.firebaseConfig = firebaseConfig
  return contextConfig
}

// Hook up the SDK exception logging system to forward to BugSnag.
export const installChaiCrashlytics = () => {
  if (Chai.installCrashlytics === undefined) {
    return
  }
  const bugsnagBridge: Chai.CrashlyticsBridge = {
    logException: (error) => {
      bugsnagClient?.notify?.({
        message: `${error.name}: ${error.message}`,
        name: 'conxsdk_crashlytics_exception',
      })
    },
    logMessage: (message: string) => {
      bugsnagClient?.leaveBreadcrumb?.(message)
    },
    setCustomKey: (_key: string, _value: string) => {
      // not needed
    },
    setUserId: (_userId: string) => {
      // not needed
    },
  }
  Chai.installCrashlytics(bugsnagBridge)
}
