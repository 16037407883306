import React, { FC } from 'react'
import { useSdkLogs } from 'modules/SdkLogsProvider'
import { DropdownButton, DropdownButtonProps } from 'components/DropdownButton'
import Routes from 'types/routes'
import { useConsoleLogs } from 'modules/CustomConsole'
import { useSetupPlaywright } from 'modules/PlaywrightDeveloperTools'
import { useMediaQuery } from 'hooks'
import { useConxSdk } from 'modules/ConxSdkProvider'
import { useRemoteConfigManager } from 'modules/RemoteConfigManager'

type DevToolsProps = Omit<DropdownButtonProps, 'options'>

// media query arguments
const QUERIES = ['(min-width: 769px)']
const VALUES = [{ isNarrowScreen: false }]
const DEFAULT_VALUE = { isNarrowScreen: true }

export const DevTools: FC<DevToolsProps> = ({ className, ...rest }) => {
  const { isNarrowScreen } = useMediaQuery<{ isNarrowScreen: boolean }>(
    QUERIES,
    VALUES,
    DEFAULT_VALUE,
  )

  const { userAgent } = useConxSdk()
  const { openSdkLogsModal } = useSdkLogs()
  const { openConsoleLogsModal } = useConsoleLogs()
  const { openRemoteConfigManagerModal } = useRemoteConfigManager()
  const { openPlaywrightModal } = useSetupPlaywright()

  return (
    <div className={className}>
      <DropdownButton
        accessibilityLabel='dev tools dropdown'
        size={isNarrowScreen ? 'base' : 'small'}
        content={isNarrowScreen ? undefined : 'Toolkit'}
        primary='black'
        options={[
          {
            label: 'SDK Logs',
            onClick: openSdkLogsModal,
          },
          {
            label: 'Remote Config Manager',
            onClick: openRemoteConfigManagerModal,
          },
          {
            disabled: !userAgent.isBluefy,
            label: 'Custom Console',
            onClick: openConsoleLogsModal,
          },
          {
            disabled: true,
            label: 'Mock Usage (TBD)',
          },
          {
            href: Routes.Diagnostics,
            label: 'BLE Diagnostics',
            local: true,
          },
          {
            href: Routes.FeedbackDeveloperTools,
            label: 'Feedback Tools',
            local: true,
          },
          {
            label: 'Playwright',
            onClick: openPlaywrightModal,
          },
        ]}
        {...rest}
      />
    </div>
  )
}
