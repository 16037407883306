import { useContext } from 'react'
import { FeedbackContext } from '../FeedbackProvider'


export const useFeedback = () => {
  const context = useContext(FeedbackContext)

  if (context === undefined) {
    throw new Error(
      '`useFeedback` hook must be used within a `Feedback` component',
    )
  }

  return context
}
