import React, { FC, useState } from 'react'
import { classNames } from 'utils/css'
import styles from './Textarea.module.scss'
import { TextareaProps } from './types'

export const Textarea: FC<TextareaProps> = ({
  accessibilityLabel,
  className,
  defaultValue,
  disabled,
  error,
  errorText,
  fieldClassName,
  helperText,
  id,
  label,
  labelClassName,
  name,
  readOnly,
  textareaClassName,
  value,
  ...rest
}) => {
  const hasValue = Boolean(value)
  const [isLabelHidden, setIsLabelHidden] =
    useState<boolean>(hasValue || Boolean(defaultValue))

  const htmlId = id || `textarea-${name}`
  const accessibilityText = accessibilityLabel
    || `textarea ${name}`

  const hasError = error || Boolean(errorText)
  const shouldHideLabel = isLabelHidden || hasValue

  const fieldClasses = classNames(
    styles.Textarea,
    readOnly && styles.readOnly,
    disabled && styles.disabled,
    shouldHideLabel && styles.labelHidden,
    hasError && styles.error,
    fieldClassName,
  )

  const labelClasses = classNames(
    styles.textareaLabel,
    disabled && styles.disabled,
    shouldHideLabel && styles.labelHidden,
    labelClassName,
  )

  const textareaClasses = classNames(
    styles.textareaInput,
    shouldHideLabel && styles.labelHidden,
    textareaClassName,
  )

  return (
    <div className={className}>
      <div className={fieldClasses}>
        <label htmlFor={htmlId} className={labelClasses}>
          {label}
        </label>
        <textarea
          {...rest}
          aria-labelledby={accessibilityText}
          className={textareaClasses}
          defaultValue={defaultValue}
          disabled={disabled}
          id={htmlId}
          name={name}
          onBlur={e => {
            setIsLabelHidden(Boolean(e?.target?.value))
            rest?.onBlur?.(e)
          }}
          onFocus={e => {
            setIsLabelHidden(true)
            rest?.onFocus?.(e)
          }}
          placeholder={null}
          readOnly={readOnly}
          value={value}
        />
      </div>
      {helperText && (
        <div className={styles.helperText}>
          {helperText}
        </div>
      )}
      {errorText && (
        <div className={styles.errorText}>
          {errorText}
        </div>
      )}
    </div>
  )
}
